import { axios, config } from "../axios";

export const CheckToken = () => {
    return axios
        .get("https://darah-dev.4explain.net/api/v1/validation/token", config)
        .then(function (response) {            
            return response.data;
        })
        .catch(function (error) {
        });
};
