import { axios, baseURL, config } from "../../axios";

export const getCartBooks = () => {
    return axios(baseURL + "carts?limit=15", config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            
        });
};

export const deleteCartBooks = (id) => {
    return axios
        .delete(baseURL + `carts/${id}`, config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            
        });
};

export const addBookToCart = (slug, quantity) => {
    return axios
        .post(
            baseURL + `carts`,
            {
                quantity: quantity,
                product: slug,
            },
            config
        )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            
            throw error;
        });
};

export const addBookToCartBlucks = (items) => {
    return axios
        .post(baseURL + `carts/blucks`,{items} ,config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            
            throw error;
        });
};