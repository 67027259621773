import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from "react-leaflet";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

const LocationMap = ({setselectedLocation}) => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: {
      lat: 24.641,
      lng: 46.718,
    },
    error: null,
  });
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const locationData = await fetchLocation(query);
    setLocation(locationData);
  };

  const fetchLocation = async (query) => {
    try {
      const { data } = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          query
        )}&format=json`
      );
      if (data.length === 0) {

        return location;
      }
      const { lat, lon } = data[0];
      return {
        loaded: true,
        coordinates: {
          lat: parseFloat(lat),
          lng: parseFloat(lon),
        },
        error: null,
      };
    } catch (error) {
      console.error("Error fetching location data:", error);
      return { ...location, error: error.message };
    }
  };

  const LocationMarker = () => {
    const map = useMap();
    if (location.loaded) {
      map.flyTo([location.coordinates.lat, location.coordinates.lng], 13);
    }
    return null;
  };

  const MapMarker = new L.Icon({
    iconUrl: require("../../assets/images/MapMarker.png"),
    iconSize: [35, 35],
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
      error: null,
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: 24.641,
        lng: 46.718,
      },
      error: console.log(
        i18n.language === "en"
          ? "Geolocation is not supported by this browser. " + error.message
          : "تحديد الموقع غير مدعومة علي المتصفح " + error.message
      ),
    });
  };

  useEffect(() => {
  setselectedLocation(location);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          setLocation({
            loaded: true,
            coordinates: {
              lat: latitude,
              lng: longitude,
            },
            error: null,
          });
        },
        (err) => {
          console.error(err);
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  function pickMyLocation(e) {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      console.log(
        i18n.language === "en"
          ? "Geolocation is not supported by this browser."
          : "تحديد الموقع غير مدعومة علي المتصفح."
      );
    }
  }

  // Function to allow clicking on the map to select a new location
  const ClickToSelectLocation = () => {
    useMapEvents({
      click(e) {
        setLocation({
          loaded: true,
          coordinates: {
            lat: e.latlng.lat,
            lng: e.latlng.lng,
          },
          error: null,
        });
      },
    });

    return null;
  };

  return (
    <div className="mt-4 mb-5" dir={i18n.language === "en" ? "ltr" : "rtl"}>
      <Row className="gy-3">
        <Col lg={3} >
          <form onSubmit={handleSubmit}
          className="d-flex justify-content-center align-items-center"
          >
            <div className="d-flex">
              <input
              hidden
                type="text"
                placeholder={t("EnterLocationAddress")}
                className="form-control rounded-0 text-capitalize"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div className="d-flex gap-2">
                {/* <button
                  hidden
                  type="submit"
                  className="btn btn-primary  rounded-0 d-flex justify-content-center align-items-center text-capitalize"
                  style={{ height: "100%", width: "100px" }}
                >
                  {t("digitalcontent.search")}
                </button> */}
                <button
                  onClick={pickMyLocation}
                  className="btn btn-primary rounded-0 d-flex justify-content-center align-items-center text-capitalize"
                  style={{ width: "130px" }}
                >
                  {t("pickMe")}
                </button>
              </div>
            </div>
          </form>
        </Col>

        <Col lg={12} className="d-flex justify-content-center align-items-center">
          <div style={{ height: "350px", width: "50%" }} >
            {location.coordinates.lat && location.coordinates.lng ? (
              <MapContainer
                center={[location.coordinates.lat, location.coordinates.lng]}
                zoom={9}
                style={{ height: "100%", width: "100%", position: "sticky" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {location.loaded && (
                  <Marker
                    position={[location.coordinates.lat, location.coordinates.lng]}
                    icon={MapMarker}
                  />
                )}
                <ClickToSelectLocation /> {/* Enable click to select location */}
                <LocationMarker />
              </MapContainer>
            ) : (
              <div>Loading map...</div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LocationMap;
