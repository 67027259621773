import "./header.css";
import Container from "react-bootstrap/Container";
import { useEffect, useState, useContext } from "react";
import NavbarIcons from "./NavbarIcons";
import {Navbar} from "react-bootstrap";
import MenuItems from "./MenuItems";
import {useTranslation} from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import NavbarIconsMobileScreen from './NavbarIconsMobileScreen';
import { getHallPalace } from './../../api/services/Navbar-items/king-hall&palaceNavs';
import {  getEservicesMenuItems } from './../../api/services/Navbar-items/Eservices-home-api';
import { CartContext } from './../../context/CartContext/CartContext';
import { useLocation } from 'react-router-dom';
import { CheckToken } from "../../api/services/checkToken-api";
import { functions } from "lodash";

const Header = ({links}) => {
  const {t, i18n} = useTranslation();
  const [scroll, setScroll] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { userCart } = useContext(CartContext);
  let location = useLocation();
  const AllParams = new URLSearchParams(location.search);
  const whichPage = location.pathname
  const navigate = useNavigate()
  let langSelected = localStorage.getItem("i18nextLng");
      if (langSelected === "en-US" || langSelected === "") {
         localStorage.setItem("i18nextLng", "ar");
         window.location.reload()
      }
      const [isLogin , setIsLogin] =useState(false);
      const [router,setRouter ] =useState('/');
      let URLHome =process.env.REACT_APP_DARAH_PORTAL_URL;
// let URLHome = "http://localhost:3000/";
      
      useEffect(()=>{
        if (isLogin) {
          // URLHome = "http://localhost:3000/";
          window.location.reload();
        }

            CheckToken().then(res => {                   
            if(res.message ===  true){
            setIsLogin(false);
            } 
            }).catch(err => {    
            setIsLogin(true);
            if (localStorage.getItem("token") || sessionStorage.getItem("token")){
              window.location.reload();
            }
            localStorage.removeItem("token");
            localStorage.removeItem("isRemember");
            sessionStorage.removeItem("token");   
          })
      },[])
let URLToken ="";
const tokenStore = AllParams.get('token');


const isRememberStore = AllParams.get('isRemember') || localStorage.getItem("isRemember");
function saveToken(token, remember) {
          if (remember) {
          localStorage.setItem("token", token);
          sessionStorage.removeItem("token");
          }else {
            sessionStorage.setItem("token", token);
            localStorage.removeItem("token");
          }
        }
        if (!localStorage.getItem("token") && !sessionStorage.getItem("token")) {          
          if (tokenStore) {
            const cleanedToken = tokenStore.replace(/\?\/isRemember=(true|false)/, '');            
            saveToken(cleanedToken, isRememberStore === 'true');
            
          }
          } else {
            let token = isRememberStore === 'true' ? localStorage.getItem("token") : sessionStorage.getItem("token");
            if (token && (localStorage.getItem("token") || sessionStorage.getItem("token"))) {
                URLToken = `?token=${token}&isRemember=${isRememberStore}`;
              }else{
                URLToken = ""
              }
               AllParams.delete("token");
            AllParams.delete("isRemember");
            const newUrl = `${window.location.pathname}?${AllParams.toString()}`;
            window.history.replaceState(null, "", newUrl);
        }        

  const [menuItems] = useState([
    {
       title: t("header.home"),
       url: URLHome+URLToken,
      //  external_url: "true",
    },
    {
       title: `${t("header.about.about_darah")}`,
       url: "#!",
       submenu: [
          {
             title: `${t("header.about.about_darah")}`,
             url:URLHome +"about-us"+URLToken,
              
            
             //  external_url: "true",
          },
          {
            title: `${t("header.about.king_and_darah")}`,
            url:URLHome+"king-and-darah"+URLToken,
         },
          {
             title: `${t("header.about.darah_board")}`,
             url:URLHome+"board"+URLToken,
            //  external_url: "true",
          },
          // {
          //    title: `${t("header.about.departments_centers")}`,
          //    url:URLHome+"departments",
          //   //  external_url: "true",
          // },
          {
             title: `${t("header.about.membership")}`,
             url:URLHome+"membership"+URLToken,
            //  external_url: "true",
          },
       ],
    },
    {
       title: `${t("nav_items.e-services")}`,
       url: "/e-services",
       submenu: [
          {
             title: `${t("nav_items.all_services")}`,
             url:URLHome+"e-services"+URLToken,
          },
       ],
    },
    {
      title: `${t("header.digital_content.darah_magazine")}`,
      url: process.env.REACT_APP_DARAH_JOURNAL_URL,
      external_url: true,
    },
   //  {
   //     title: `${t("nav_items.digital_content")}`,
   //     url: "#!",
   //     submenu: [
   //       {
   //          title: `${t('header.digital_content.all')}`,
   //          url:URLHome+"digital-content/all",
   //       },
   //    ],
   //  },
    {
       title: `${t("nav_items.projects")}`,
       url:URLHome+"projects"+URLToken,
    },
    {
       title: `${t("nav_items.media_center")}`,
       url: "#!",
       submenu: [
          {
             title: `${t("nav_items.news")}`,
             url:URLHome+"news"+URLToken,
          },
          {
             title: `${t("nav_items.events")}`,
             url:URLHome+"events"+URLToken,
          },
          {
             title: `${t("nav_items.media_reports")}`,
             url:URLHome+"media-center/reports"+URLToken,
          },
          {
             title: `${t("nav_items.open_access")}`,
             url:URLHome+"open-books"+URLToken,
          },
          {
             title: `${t("nav_items.media_center")}`,
             submenu: [
                {
                   title: `${t("nav_items.photos")}`,
                   url:URLHome+"media-center/photos"+URLToken,
                },
                {
                   title: `${t("nav_items.video_library")}`,
                   url:URLHome+"media-center/videos"+URLToken,
                },
                {
                   title: `${t("nav_items.audio_books_library")}`,
                   url:URLHome+"media-center/audio_books"+URLToken,
                },
                {
                   title: `${t("nav_items.infographic")}`,
                   url:URLHome+"media-center/infographics"+URLToken,
                },
             ],
          },
       ],
    },
    {
       title: `${t("personalcontent.darah_store")}`,
       url: "/",
       icon: <i className="fa fa-shopping-cart storeLinkIcon"></i>,
    },
  ])
  
  const fetchHallDataAndSetSlugForEachPage = async () => {
    try {
       const hallData = await getHallPalace();
       hallData?.map((singleHall) => {
          menuItems[5].submenu[4].submenu.push(singleHall);
       });
    } catch (error) {
       console.error(error);
    } finally {
       setIsFetchingData(false);
    }
 };

  const fetchServiceDataAndSetSlugForEachPage = async () => {
    try {
      const serviceData = await getEservicesMenuItems();
      serviceData?.map((singleService) => {
            menuItems[2].submenu.push(singleService);
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetchingData(false);
      }
  };

//   const fetchDigitalContentDataAndSetSlugForEachPage = async () => {
//    try {
//       const DigitalContentData = await getDigitalContentMenuItems();
//       DigitalContentData?.map((singleDigitalContent) => {
//          menuItems[3].submenu.push(singleDigitalContent);
//       });
//    } catch (error) {
//       console.error(error);
//    } finally {
//       setIsFetchingData(false);
//    }
// };

  useEffect(() => {
      if(isFetchingData){
         fetchServiceDataAndSetSlugForEachPage();
         fetchHallDataAndSetSlugForEachPage();
         // fetchDigitalContentDataAndSetSlugForEachPage();
      }
   }, []);

 useEffect(() => {
  
  window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 45);
  });
  return () => {
      window.removeEventListener("scroll", () => {});
  };
}, [scroll]);

  return (
    <>
      {/* <!-- Header start --> */}
      <header
        className="header-one"
        // style={{ marginBottom: scroll ? "73px" : "50px" }}
      >
              <div className={`${"site-navigation " + (scroll ? "navbar-fixed" : "")}`} style={{backgroundColor: whichPage != "/" ? "#425260" : ""}} dir={i18n.language === "en" ? "ltr" : "rtl"}>
                  {/* <div className="position-relative"> */}
                    <Container fluid className="hide-heavy-nav">
                      <div className="d-flex justify-content-between align-items-center">

                      <div className="d-flex flex-row-reverse flex-lg-row justify-content-between flex-grow-1">
                      <Link to={`${URLHome+URLToken}`} className="d-flex align-items-center mx-auto mx-lg-0">
                      <Navbar.Brand>
                          <img alt="شعار الدارة" 
                          style={{maxHeight: '80px'}} 
                          className="darahLogoHeader"
                          src={links?.logo_image} />
                      </Navbar.Brand>
                      </Link>

                        <Navbar variant="dark" expand="sm" className="p-0 siteNavs mx-lg-auto" expanded={collapseMenu}>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav" className="align-items-center">
                                <ul
                                style={{direction: i18n.language == "en" ? "ltr" : ""}} 
                                className="nav navbar-nav mx-auto mb-0 maxHeightResponsive">
                                    {menuItems.map((menu, index) => {
                                        const depthLevel = 0;
                                        return <MenuItems items={menu} key={index} depthLevel={depthLevel} setCollapseMenu={setCollapseMenu} />;
                                    })}
                                </ul>
                            </Navbar.Collapse>
                        </Navbar>

                          {/* <Navbar.Toggle aria-controls="light-navbar-collapse" /> */}
                          {/* <div className="justify-content-center align-items-center hide-light-nav" style={{height: "60px", width: "30px",}}>
                            {collapseMenu ? <>
                            <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" class={`fas fa-times text-white fs-3`}></i>
                            </> : <>
                            <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" class={`fas fa-bars text-white fs-3`}></i>
                            </>}
                          </div> */}
                            

                        </div>
                        
                        <NavbarIcons
                            links={links}
                        />

                      </div>
                      
                    </Container>
                    

                    <div className="position-relative hide-light-nav">

                    {collapseMenu ? <>
                      <Navbar expand="sm" className="p-0 colorful-nav" expanded={collapseMenu}>
                          <Navbar.Collapse id="light-navbar-collapse" className="d-flex">
                                  <ul
                                  style={{direction: i18n.language == "en" ? "ltr" : ""}}
                                  className="nav navbar-nav mb-0 d-flex flex-column">
                                      {menuItems.map((menu, index) => {
                                          const depthLevel = 0;
                                          return <MenuItems items={menu} key={index} depthLevel={depthLevel} setCollapseMenu={setCollapseMenu} />;
                                      })}

                                      <li onClick={() => setCollapseMenu(false)} className="menu-items">
                                        <Link
                                          to={"/favorites"}
                                          className="list-group-item d-flex justify-content-start"
                                          >
                                            <span>{i18n.language == "en" ? "Favorites" : "المفضلة"}</span>
                                          </Link>
                                    </li>

                                    <NavbarIconsMobileScreen links={links} setCollapseMenu={setCollapseMenu} />

                                  </ul>
                              </Navbar.Collapse>
                          </Navbar>
                    </> : <></>}

                    <Container fluid>
                    <div className="d-flex justify-content-between align-items-center">

                      <div className="d-flex justify-content-center align-items-center" style={{height: "60px", width: "30px",}}>
                          {collapseMenu ? <>
                          <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" class={`fas fa-times text-white fs-3`}></i>
                          </> : <>
                          <i onClick={() => setCollapseMenu(!collapseMenu)} role="button" class={`fas fa-bars text-white fs-3`}></i>
                          </>}
                      </div>

                      <div>
                        <Link to={`${process.env.REACT_APP_DARAH_PORTAL_URL}`} className="d-flex align-items-center mx-auto mx-lg-0">
                          <Navbar.Brand>
                              <img alt="شعار الدارة"
                              style={{maxHeight: "55px"}}
                              src={links?.logo_image} />
                          </Navbar.Brand>
                        </Link>
                        </div>

                          <div className="d-flex align-items-center">
                            <Link
                              state={{ isPublicSearch: true, searchKey: null }}
                              to={process.env.REACT_APP_DARAH_PORTAL_URL+"searchdarah"}
                              className="list-group-item d-flex align-items-center"
                            >
                              <i className={`fal fa-search text-white fs-5 fa-fw fw-bold mx-2`}></i>
                            </Link>

                            {/* <CartIcon /> */}

                            {/* {isAuthenticated && */}
                            <div className={`position-relative ${i18n.language === "en" ? "ltr" : ""}`}>
                            <span style={{top: "-10px", [i18n.language === "en" ? "right" : "left"]: "-4px"}} className="badge badge-warning badgeCart" id="lblCartCount">
                            {" "}
                                {userCart?.totalQty ? userCart.totalQty : 0}{" "}
                            </span>
                                <Link
                                to={"/cart"}
                                className="list-group-item d-flex align-items-center"
                                >
                                  {" "}
                                  <i class="fal fa-shopping-cart text-white fs-5 fa-fw mx-1"></i>
                                </Link>
                            </div>
                            {/* } */}
                        </div>

                      </div>


                    </Container>
                    </div>

              </div>
        {/* <div className={`${"site-navigation " + (scroll ? "navbar-fixed" : "")}`}>
          <Container fluid>
                <NavbarContainer links={links} />
          </Container>
        </div> */}
          
      </header>
    </>
  );
};

export default Header;