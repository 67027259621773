import { axios, baseURL, config } from "../../axios";
export const getfavoriteData = (page = 1, limit = 16) => {
  return axios(baseURL + `wishlists?limit=${limit}&page=${page}`, config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
    });
};

export const addBooktoFavorite = (bookId) => {
    return axios
        .post(baseURL + "wishlists", { product: bookId }, config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
                  throw error;
        });
};
