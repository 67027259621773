import { Pagination } from "react-bootstrap";
import { ParseLabelToOption } from "../index";
import { useTranslation } from "react-i18next";
import "../../pages/SearchGate/search-gate.css"

export const Paginator = ({data,setCurrentPage}) => {
    const {t, i18n} = useTranslation()
    if (!(data?.data?.length && data?.meta?.total > data?.meta?.per_page)) {
        return "";
    }
    const handlePageChange = (page) => {
        try {
            let pageNumber = page?.label
            if (pageNumber) {
                const urlSearchParams = new URLSearchParams(page?.url?.split('?')[1]);
                pageNumber = urlSearchParams.get("page");
            }
            setCurrentPage(pageNumber)
        } catch (err) {
        }
    }
    const buildPaginator = () => {
        const regex = /\b(\d+)\b/;
      
        return data?.meta?.links?.map((link, index) => {
          if (!link.url) return null;
      
          const label = ParseLabelToOption(link?.label);
          const match = regex.exec(label);
          let name = '';
      
          if (match) {
            name = match[1];
          } else {
            name = label.includes('«') ? i18n.language == "ar" ? <i class="fas fa-angle-right"></i> : <i class="fas fa-angle-left"></i> : i18n.language == "ar" ? <i class="fas fa-angle-left"></i> : <i class="fas fa-angle-right"></i>;
          }
      
          return (
            <Pagination.Item
              key={index}
              active={link?.active}
              onClick={() => handlePageChange(link)}
            >
              {name}
            </Pagination.Item>
          );
        });
      };
    return <Pagination
        className={`justify-content-center my-5 mx-auto activeColor flex-row`}>
        {buildPaginator()}
    </Pagination>
}