import { axios, commonURL, config } from "../../axios";

export const uploadFile = (data) => {
    return axios
        .post(commonURL + "attachment/file", data, config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
};
