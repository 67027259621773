import Dropdown from "react-bootstrap/Dropdown";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import "./header.css";
import {useAuth} from "../../context/authContext/authContext";
import {logout} from "../../api/services/auth-api/logout-api";
import {config} from "../../api/axios";
import {useTranslation} from "react-i18next";
import { getNotificationsCount } from "../../api/services/notifications-api/notifications-api";
import CartIcon from './CartIcon';

const NavbarIcons = ({links}) => {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const [language, setLanguage] = useState(i18n.language);
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    let ele = localStorage.getItem("token");
    const { isAuthenticated, updateAuthState } = useAuth();
    const [notificationsCount, setNotificationsCount] = useState(0);
    const whatsAppUrl = links?.whatsapp
    const match = whatsAppUrl?.match(/\d+/);

    if (!ele) {
        ele = sessionStorage.getItem("token");
    }

    useEffect(() => {
        if (ele === null || ele === "") updateAuthState(false);
        else updateAuthState(true);
    }, [ele,updateAuthState]);

    const Exit = async () => {
        try {
            const response = await logout();
            if (response.data === "Done") {
                navigate("/account-management");
                // window.location.reload()
                updateAuthState(false);
                localStorage.removeItem("token");
                sessionStorage.removeItem("token");
               localStorage.removeItem("isRemember");
                localStorage.removeItem("isAuthenticated");
                window.location.reload();
            }
        } catch(err) {
        }
    };
    const handleButtonClick = () => {
        setShowToast(true);
    };

    const handleLanguageChange = (newLanguage) => {
        if (newLanguage !== language) {
            setLanguage(newLanguage);
            i18n.changeLanguage(newLanguage);
            config.headers["Accept-Language"] = i18n.language;
            window.location.reload();
        }
    };
    
    useEffect(() => {
      const fetchData = async () => {
         try {
            if (isAuthenticated) {
               const res = await getNotificationsCount();
               setNotificationsCount(res?.total_count ?? 0);
            }
         } catch (err) {
            console.error("Error fetching notifications count:", err);
         }
      };

      fetchData();
   }, [location, notificationsCount]);
    return (<>
        <div className={`left-list-icon align-items-center justify-content-between hide-heavy-nav`}>

               <Link
                  state={{ isPublicSearch: true, searchKey: null }}
                  to={process.env.REACT_APP_DARAH_PORTAL_URL+"searchdarah"}
                  className="list-group-item d-flex align-items-center hideHeavyNav"
               >
                  <i className={`fal fa-search text-white fs-6 mx-1`}></i>
               </Link>

               {isAuthenticated ? <>
               <Link
                  to={"/favorites"}
                  className="list-group-item d-flex align-items-center hideHeavyNav"
                  >
                  <i class="fal fa-heart text-white fs-6 mx-2"></i>
               </Link>
               </> : <></>}

         <CartIcon />


            <div className={`d-flex`}>

            <Dropdown className="mx-1">
                  <Dropdown.Toggle
                     className={
                        "d-flex justify-content-between align-items-center my-0 p-0"
                     }
                  >
                     <i className="fas fa-chevron-down d-flex justify-content-center align-items-center fa-xs fa-fw"></i>
                     {/* <div className={"px-1 text-capitalize fs-6"}>
                        {language === "ar" ? "ع" : "En"}
                     </div> */}
                     <div className="d-flex justify-content-center align-items-center px-1"><i class="fal fa-globe fs-6"></i></div>

                  </Dropdown.Toggle>
                  {language === "en" ? (
                     <Dropdown.Menu>
                        <Dropdown.Item
                           className="fs-6"
                           onClick={() => handleLanguageChange("en")}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                           className="justify-content-center fs-6"
                           onClick={() => handleLanguageChange("ar")}
                        >
                           العربية
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  ) : (
                     <Dropdown.Menu>
                        <Dropdown.Item
                           className="fs-6"
                           onClick={() => handleLanguageChange("en")}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                           className="justify-content-center fs-6"
                           onClick={() => handleLanguageChange("ar")}
                        >
                           العربية
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  )}
            </Dropdown>

            <div className={`position-relative d-flex mx-1 ${i18n.language === "en" ? "ltr" : ""}`}>
                  {!isAuthenticated ? (
                     <Link to={"/account-management"}>
                        <div
                           className={
                              "userIconLog rounded-2 d-flex justify-content-between align-items-center"
                           }
                        >
                           <div className={"loginWord"}>
                              {t("personalcontent.sign_in")}
                           </div>
                           {/* <i className={"far fa-user userIcon"}></i> */}
                        </div>
                     </Link>
                  ) : (
                     <>
                        <span
                           className="badgeCart badge"
                           id="lblCartCount"
                        >
                           {" "}
                           {+notificationsCount || 0}{" "}
                        </span>
                        <Dropdown className={"px-1 d-flex align-items-center"}>
                           <Dropdown.Toggle
                              id="dropdown-basic"
                              className={"userIcon pb-0 profileIcon"}
                           >
                              <i className={"far fa-user"}></i>
                           </Dropdown.Toggle>
                           <Dropdown.Menu className={"headerSideIconsMenu"}>
                              {isAuthenticated && (
                                 <Link
                                    to={"/profile?tabName=personalProfile"}
                                    className={"dropdown-item"}
                                 >
                                    <i
                                       aria-hidden={"true"}
                                       className={`far fa-user-circle ${i18n.language == "en" ? "en-icon" : ""}`}
                                    ></i>
                                    {t("header.personal_profile")}
                                 </Link>
                              )}

                              {isAuthenticated && (
                                 <Dropdown.Item
                                 //  onClick={handleButtonClick}
                                 >
                                    <Link
                                        to={"/profile?tabName=notifications"}
                                       className="position-relative p-0"
                                    >
                                       <i
                                          aria-hidden={"true"}
                                          className={`fal fa-bell-plus ${i18n.language == "en" ? "en-icon" : ""}`}
                                       ></i>{" "}
                                       {t("header.notifications")}
                                       <span
                                          className="badge mx-2"
                                          id="lblCartCount"
                                       >
                                          {" "}
                                          {notificationsCount}{" "}
                                       </span>
                                    </Link>
                                 </Dropdown.Item>
                              )}

                              {isAuthenticated ? (
                                 <Dropdown.Item className="profileMenuLinks" onClick={Exit}>
                                    <i
                                    style={{width: "25px", height: "35px"}} className={`fas fa-power-off ${i18n.language == "en" ? "en-icon" : ""}`}></i>
                                    {t("header.sign_out")}
                                 </Dropdown.Item>
                              ) : (
                                 <Dropdown.Item>
                                    <Link className="profileMenuLinks" to={"/account-management"}>
                                       <i
                                       style={{width: "25px", height: "35px"}} className={`fas fa-power-off ${i18n.language == "en" ? "en-icon" : ""}`}></i>{" "}
                                       {t("header.sign_in")}
                                    </Link>
                                 </Dropdown.Item>
                              )}
                           </Dropdown.Menu>
                        </Dropdown>
                     </>
                  )}
            </div>

          
            
            </div>  

        </div>

    </>);
};

export default NavbarIcons;